<template>
  <div class="pageContol formBOX">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的课程/课件</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课件{{ btnshow == false && stu != 'copy' ? "编辑" : btnshow ==
            false && stu == 'copy' ? "复制" : "查看" }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="10rem" class>
              <el-form-item label="课件名称" prop="kpointName" class="form-item">
                <el-input v-model="ruleForm.kpointName" :disabled="btnshow" size="small" placeholder="请输入课件名称" />
              </el-form-item>
              <el-form-item label="培训类型" prop="Trainingtype" class="form-item">
                <el-input v-if="btnshow" v-model="ruleForm.Trainingtype" :disabled="btnshow" size="small"
                  placeholder="请输入课件名称" />
                <tree v-else ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" @clearParams="clearParams"
                  size="small" type typeStu />
              </el-form-item>
              <el-form-item label="课件学时" prop="kpointLessonNum" class="form-item">
                <el-input-number :disabled="btnshow" size="medium" :step="0.1" v-model="ruleForm.kpointLessonNum"
                  @change="handleChange" :min="0.1"></el-input-number>
              </el-form-item>
              <el-form-item label="关联讲师" prop="qualificationId" class="form-item form-itemteache">
                <el-select v-model="ruleForm.qualificationId" clearable filterable size="small" placeholder="请选择讲师">
                  <el-option v-for="item in teachertree" :key="item.teacherQualificationId"
                    :label="item.teacherQualificationName" :value="item.teacherQualificationId">
                    <div style="display: flex;justify-content:space-between;align-items:center">
                      <span>{{ item.teacherQualificationName }}</span>
                      <el-popover placement="right" v-if="item.teacherQualificationId != '-1000'" trigger="hover">
                        <el-button type="text" slot="reference">证书</el-button>
                        <template>
                          <el-image style="width: 400px; height: 400px;" fit="contain"
                            :src="ylzpUrl || item.certPhotos[0]">
                          </el-image>
                          <div class="ylzpList">
                            <el-image class="ylzpList_1" fit="contain" v-for="(item1, index1) in item.certPhotos"
                              :key="index1" :src="item1" @mouseenter="setMouseenter(item1)">
                            </el-image>
                          </div>
                        </template>
                      </el-popover>
                    </div>
                  </el-option>
                </el-select>
                <el-button style="margin-left:10px" v-show="btnshow == false" size="small" class="bgc-bv"
                  :disabled="userJson.tempAccount" @click="addteacher()">新增讲师</el-button>
              </el-form-item>
              <el-form-item label="上传课件" prop="kpointVideoId" class="form-item df mlf">
                <uploadAuth @childFn="vedioId" :watermark="auditState == '30'" :disabled="btnshow"
                  :showVideoId="{ kpointVideoId: ruleForm.kpointVideoId, kpointName: ruleForm.kpointName, kpointSource: ruleForm.kpointSource }"
                  :btnshow="auditState == 20 || auditState == 30 || ruleForm.kpointSource != 25 ? false : true"
                  :btnshow2="ruleForm.showTrySeeBtn" size="small" />
              </el-form-item>
              <el-form-item label="上传字幕文件" prop="fileName" class="form-item ">
                <el-upload v-model="ruleForm.fileName" v-if="!ruleForm.fileName" class="upload-demo upload-btn"
                  :action="actionUrl" :on-error="handleError" :on-success="handleSuccess" :on-change="uploadChange"
                  :show-file-list="false" :auto-upload="false">
                  <el-button size="small" class="bgc-bv">上传字幕文件</el-button>
                </el-upload>
                <span style="color:#f46173" v-else>
                  {{ ruleForm.fileName }}
                  <a @click="reomveExl" style="color: #5bb5ff">删除</a>
                </span>
              </el-form-item>
              <el-form-item label="课后习题" class="form-item form-itemteache">
                <el-input v-if="btnshow" v-model="ruleForm.paperName" :disabled="btnshow" size="small"
                  placeholder="请输入课后习题" />
                <el-select v-else v-model="ruleForm.paperId" filterable size="small" placeholder="请选择"
                  style="width:100%" @change="selechange">
                  <el-option v-for="item in ExercisesList" :key="item.paperId" :label="item.paperName"
                    :value="item.paperId"></el-option>
                </el-select>
                <el-button class="bgc-bv" size="small" style="margin-left:10px" v-show="btnshow == false"
                  :disabled="userJson.tempAccount" @click="addExercises()">新增</el-button>
                <el-button class="bgc-bv" size="small" v-show="ruleForm.Exercises"
                  @click="lookExercises(ruleForm.paperId)">预览</el-button>
              </el-form-item>
              <el-form-item label="课件封面" prop="thumbnail" :class="[ruleForm.id ? 'item-disabled' : '', 'form-item']">
                <el-upload :disabled="btnshow" :on-change="handleAvatarSuccess2" :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine" :show-file-list="false" class="img-el-upload" action
                  accept="image/png, image/gif, image/jpg, image/jpeg">
                  <div>
                    <el-image :src="ruleForm.thumbnail || require('@/assets/develop.png')" fit="contain"
                      class="imgCenter"></el-image>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item label=" " class="form-item form-item-hint">
                <p>建议上传尺寸224*160。</p>
              </el-form-item>
              <el-form-item label="课件描述：" class="form-item form">
                <div class="flexdc">
                  <el-input v-model="ruleForm.kpointDescribe" :rows="6" class="input_w_80" maxlength="500"
                    show-word-limit resize="none" type="textarea"></el-input>
                </div>
              </el-form-item>
              <el-form-item label="课件纲要：" class="form-item form">
                <div class="flexdc">
                  <el-input v-model="ruleForm.kpointOutline" :rows="6" class="input_w_80" maxlength="500"
                    show-word-limit resize="none" type="textarea"></el-input>
                </div>
              </el-form-item>
              <el-form-item label="审核事由" class="form-item form-item-hint" v-show="ruleForm.auditState == '40'">
                <!-- <div style="padding:15px 0;" > -->
                <el-input type="textarea" disabled v-model="ruleForm.auditReason"></el-input>
                <!-- </div> -->
              </el-form-item>
              <el-form-item label="是否发布在平台销售：" class="form-item form">
                <div>
                  <el-radio-group v-model="ruleForm.salesState" :disabled="btnshow" @change="radioChange">
                    <el-radio label="20">是</el-radio>
                    <el-radio label="10">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item el-form-item label=" " class="form-item form" v-show="radio == 20">
                <el-checkbox :disabled="btnshow" v-model="checked">请阅读本协议《平台课程服务协议》</el-checkbox>
              </el-form-item>
            </el-form>
            <div class="form-btn">
              <el-button class="bgc-bv" @click="Cancel">取消</el-button>
              <el-button class="bgc-bv" :disabled="ruleForm.kpointSource == '25' || userJson.tempAccount"
                @click="formSave('ruleForm', '10')">保存</el-button>
              <el-button class="bgc-bv" v-show="btnshow == false"
                :disabled="ruleForm.kpointSource == '25' || userJson.tempAccount"
                @click="formSave('ruleForm', '20')">提交审核</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Teacherpop ref="Teacherpop" />
    <addtestpop ref="addtestpop" @childback="childback" @getList="getExercises" />
    <PaperShow ref="PaperShow" />
  </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import uploadAuth from "@/components/uploadAuth";
import Form from "@/mixins/Form";
import Teacherpop from "@/views/resourse/popup/Teacherpop";
import addtestpop from "@/views/resourse/popup/addtestpop";
import tree from "@/components/treePopup";
import PaperShow from "@/views/resourse/popup/PaperShow";
import { mapGetters } from "vuex";
export default {
  name: "viewKpoint",
  mixins: [UploadPic, DatePicker, Form],
  components: {
    Teacherpop,
    uploadAuth,
    addtestpop,
    tree,
    PaperShow
  },
  data() {
    return {
      ruleForm: {
        kpointId: '',
        kpointName: "",
        trainTypeId: "",
        kpointLessonNum: "",
        Exercises: "",
        paperId: "",
        kpointVideoId: "",
        thumbnail: "",
        fileKey: "",
        paperName: "",
        type: "",
        qualificationId: "",
        salesState: "",
        Trainingtype: "",
        kpointDescribe: '',
        kpointOutline: ''
      },
      rules: {
        kpointName: [
          { required: true, message: "请输入课件名称", trigger: "blur" }
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" }
        ],
        kpointLessonNum: [
          { required: true, message: "请输入课件学时", trigger: "blur" }
        ],
        qualificationId: [
          { required: true, message: "请选择讲师", trigger: "change" }
        ]
      },
      ExercisesList: [],
      datatreeList: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      videoId: '',
      teachertree: [], // 讲师列表
      radio: 10,
      checkShow: false, //  是否显示协议
      btnshow: false, //  是否显示协议
      checked: false,
      params: {}, // 培训类型参数
      auditState: "",
      createId: '',
      stu: '',
      kpointId: '',
      ylzpUrl: "", // 讲师姓名 - 证书 - 预览当前
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    })
  },
  methods: {
    // 讲师姓名 - 证书 - 鼠标在当前照片
    setMouseenter(item) {
      this.ylzpUrl = item;
    },
    // 讲师姓名 - 证书 - 预览当前 - 隐藏时触发
    setYlzpUrl() {
      this.ylzpUrl = "";
    },
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    childback(paperId, paperName) {
      this.ruleForm.Exercises = paperId;
      this.ruleForm.paperName = paperName;
    },
    init() {
      this.getList();
      this.getteachertree();
      this.getExercises();
      this.auditState = this.$route.query.auditState;
      this.kpointId = this.$route.query.kpointId;
      this.stu = this.$route.query.stu;
      this.getDetail();
      if (this.auditState == "30" || this.auditState == "20") {
        this.btnshow = true;
      } else if (this.stu == 'copy') {
        this.createId = this.$route.query.createId;
        this.btnshow = false;
      }
    },
    vedioId(videoId) {
      this.videoId = videoId;
    },
    getDetail() {
      this.$post("/biz/courseware/kpointView", {
        kpointId: this.kpointId
      }).then(result => {
        if (result.status === "0") {
          this.ruleForm = {
            coursewareId: this.$route.query.kpointId,
            kpointName: result.data.kpointName,
            trainTypeId: result.data.trainTypeId,
            kpointLessonNum: result.data.kpointLessonNum,
            paperId: result.data.paperId,
            kpointVideoId: result.data.kpointVideoId,
            thumbnail: result.data.kpointLogo,
            salesState: result.data.salesState,
            Exercises: result.data.paperId,
            qualificationId: result.data.qualificationId,
            auditState: result.data.auditState,
            auditReason: result.data.auditReason,
            paperName: result.data.paperName,
            kpointDescribe: result.data.kpointDescribe,
            kpointSource: result.data.kpointSource,
            kpointOutline: result.data.kpointOutline,
            fileName: result.data.subtitleFileName,
            subtitleKey: result.data.subtitleFile,
            subtitleFileUrl: result.data.subtitleFileUrl,
            showTrySeeBtn: result.data.showTrySeeBtn,
          };
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (result.data.trainTypeId) {
            this.params.trainFormId = result.data.trainTypeId;
            name1 = result.data.trainTypeNamePath;
          }
          if (result.data.postId) {
            this.params.postFormId = result.data.postId;
            name2 = "/" + result.data.postName;
          }
          if (result.data.industryId) {
            this.params.industryFormId = result.data.industryId;
            name3 = "/" + result.data.industryNamePath;
          }
          if (result.data.occupationId) {
            this.params.occFormId = result.data.occupationId;
            name4 = "/" + result.data.occupationNamePath;
          }
          if (result.data.trainLevelId) {
            this.params.levelFormId = result.data.trainLevelId;
            name5 = "/" + result.data.trainLevelName;
          }
          let name = (name1 + name2 + name3 + name4 + name5).replace(/,/g, "-");
          this.ruleForm.Trainingtype = name;
          this.radio = result.data.salesState;
          this.checked = result.data.salesState == 20 ? true : false;
        }
      });
    },
    // 培训类型
    getList() {
      this.$post("/sys/category/train-type/tree").then(ret => {
        if (ret.status == 0) {
          this.datatreeList = ret.data;
        }
      });
    },
    // 讲师列表
    getteachertree() {
      this.$post("/biz/teacher/queryQualificationDictionary", {}).then(res => {
        if (res.status == 0) {
          this.teachertree = [...res.data, { teacherQualificationId: '-1000', teacherQualificationName: '无讲师' }]
        }
      });
    },
    // 课后习题
    getExercises() {
      this.$post("/biz/paper/queryPaperComboBox", {}).then(ret => {
        if (ret.status == 0) {
          this.ExercisesList = ret.data;
        }
      });
    },
    // 课后习题
    selechange(val) {
      for (let i = 0; i < this.ExercisesList.length; i++) {
        let obj = this.ExercisesList.find(item => {
          return item.paperId === val;
        });
        this.ruleForm.Exercises = obj.paperId;
        this.ruleForm.paperName = obj.paperName;
      }
    },
    radioChange(val) {
      console.log(val);
      this.radio = val;
      this.checked = false;
      // if (val == 20) {
      //   this.checkShow = true;
      // } else {
      //   this.checkShow = false;
      // }
    },
    handlecascader(val) {
      this.ruleForm.Trainingtype = val;
    },
    handleteacher(val) {
      this.ruleForm.teachername = val;
    },
    // 上传图片
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.thumbnail = result.data.fileURL;
          this.ruleForm.fileKey = result.data.fileKey;
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败"
            });
          }, 300);
        });
    },
    // 保存
    formSave(formName = "ruleForm", type) {
      this.ruleForm.type = type;
      const el = this.$refs[formName];
      el.validate(valid => {
        if (valid) {
          if (this.radio == 20 && this.checked == false) {
            this.$message({
              type: "warning",
              message: "请勾选协议"
            });
            return;
          }
          this.doAjax(formName);
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项"
          });
        }
      });
    },
    doAjax() {
      let parmars = {
        kpointId: this.kpointId,
        kpointName: this.ruleForm.kpointName,
        kpointLessonNum: this.ruleForm.kpointLessonNum,
        kpointVideoId: this.videoId
          ? this.videoId
          : this.ruleForm.kpointVideoId,
        qualificationId: this.ruleForm.qualificationId,
        salesState: this.radio,
        kpointLogo: this.ruleForm.fileKey,
        kpointDescribe: this.ruleForm.kpointDescribe,
        kpointOutline: this.ruleForm.kpointOutline
      };
      if (this.ruleForm.Exercises) {
        parmars.paperName = this.ruleForm.paperName;
        parmars.paperId = this.ruleForm.Exercises;
      }
      if (this.ruleForm.type) {
        parmars.auditState = this.ruleForm.type;
      }
      if (this.ruleForm.subtitleKey) {
        parmars.subtitleFile = this.ruleForm.subtitleKey;
        parmars.subtitleFileName = this.ruleForm.fileName;
      }
      // 培训类型 trainForm postForm occForm levelForm industryForm
      if (this.params.trainFormId) {
        parmars.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmars.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmars.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmars.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmars.industryId = this.params.industryFormId;
      }
      if (this.stu == 'copy') {
        parmars.createId = this.createId
      }
      this.$post(this.stu != 'copy' ? "/biz/courseware/modify" : "/biz/courseware/copy/courseware", parmars)
        .then(ret => {
          if (ret.status == 0) {
            setTimeout(() => {
              this.$message({
                type: "success",
                message: "保存成功"
              });
              this.Cancel("1");
            }, 300);
          }
        })
        .catch(() => {
          return;
        });
    },
    addteacher() {
      this.$refs.Teacherpop.showPopUp();
    },
    addExercises() {
      this.$refs.addtestpop.showPopUp();
    },
    lookExercises(Exercises) {
      this.$refs.PaperShow.showPopUp(Exercises); // 打开弹窗预览试题
    },
    Cancel(isRefresh) {
      this.$router.push({
        name: "myresourseList",
        params: {
          refresh: isRefresh == "1" ? true : false,
          refName: "second"
        }
      });
    },
    //上传字幕文件
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isSrt = extension === "srt";
      if (!isSrt) {
        this.$message.error("只能上传后缀是.srt的文件");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.subtitleKey = result.data.fileKey;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败"
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.subtitleKey = "";
      this.actionUrl = "";
      this.$forceUpdate();
    }
  }
};
</script>
<style lang="less">
.formBOX {
  .el-form-item__content {
    width: 50%;

    .el-textarea__inner {
      resize: none;
    }
  }

  .mlf {
    .el-form-item__content {
      margin-left: 0 !important;
      display: flex;
      align-items: center;
    }
  }

  .el-input-number {
    width: 100%;
  }

  .form-itemteache {
    .el-form-item__content {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
  }

  .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      width: 10rem;
    }
  }
}

.el-cascader {
  width: 100% !important;
}

.el-icon-arrow-down {
  font-size: 12px;

  &:before {
    content: "\e6df" !important;
  }
}

.upload-btn {
  height: 40px;

  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
</style>
<style lang="less" scoped>
.ylzpList {
  width: 400px;
  overflow-x: auto;
  white-space: nowrap;
  .ylzpList_1 {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
}
</style>
